<template>
  <div class="completed">
    <div class="completed-top">
      <div class="completed-top-content">
        <div class="completed-top-content_score">
          <span class="key" v-if="info.score && info.extraScore">{{
              Number(info.score) + Number(info.extraScore)
            }}</span>
          <span>总分数</span>
        </div>
        <div class="line"></div>
        <div class="completed-top-content_score">
          <!-- <span class="key">{{ info.times }}</span> -->
          <span class="key" v-if="info.times">{{
              countDown(info.times).split(":")[0] +
              "'" +
              countDown(info.times).split(":")[1] +
              "''"
            }}</span>
          <span>用时</span>
        </div>
      </div>
    </div>
    <div class="completed-bottom">
      <div class="completed-bottom-explain">
        <div flex>
          <div class="title">卷面分：</div>
          <div v-if="info.score">{{ info.score }}分</div>
        </div>
        <div flex>
          <div class="title">额外加分：</div>
          <div>
            <span v-if="info.extraScore">{{ info.extraScore }}</span> 分
            (总分=卷面分+额外加分)
          </div>
        </div>
        <!-- <div flex>
          <div class="title">加分规则：</div>
          <div>10分钟内作答完额外加5分</div>
        </div> -->
        <template v-if="store.gameType == 1">
          <div flex>
            <div class="title">加分规则：</div>
            <div>10分钟内作答完额外加5分</div>
          </div>
          <!--          <div flex>-->
          <!--            <div class="title"></div>-->
          <!--            <div>10-15分钟内作答完额外加3分</div>-->
          <!--          </div>-->
        </template>
        <template v-else-if="store.gameType == 2">
          <div flex>
            <div class="title"></div>
            <div>15分钟内作答完额外加3分</div>
          </div>
        </template>
      </div>
      <div class="completed-bottom-des">
        关注<span class="key">升学宝</span>公众号，明日公布{{ store.gameType == 1 ? '本轮' : '复赛' }}晋级名单
      </div>
      <img
          class="completed-img"
          src="../../assets/account.png"
          alt=""
          srcset=""
      />
      <van-button class="completed-back" @click="toBack" color="#27C190"
      >返回首页</van-button
      >
    </div>
  </div>
</template>
<script>
import { countDown } from "../../util/tools";
import { store, mutations } from "@/store/index.js";
export default {
  data() {
    return {
      info: {},
      countDown: countDown,
      store: store
    };
  },
  created() {
    this.getScoreInfo();
  },
  methods: {
    toBack() {
      this.$router.push("/");
    },
    getScoreInfo() {
      // let params = {
      //   type: 1,
      //   no: JSON.parse(window.localStorage.getItem('gameSessions')) ,
      // };
      // dataApi.getScoreCompleted(params).then((res) => {
      //   const data = res.data;
      //   this.info = data;
      // });
      this.info = this.$route.query;
    },
  },
};
</script>
<style lang="less" scoped>
.completed {
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  &-top {
    height: 3.9rem;
    background: #42ad8b;
    padding-top: 0.82rem;
  }
  &-top-content {
    width: 6.9rem;
    height: 2.44rem;
    margin: auto;
    background: #2ba07b;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 0.24rem;
    color: rgba(#fdfdfd, 0.7);
    .line {
      border-right: 1px solid rgba(#fff, 0.3);
      height: 0.88rem;
    }
    .key {
      font-size: 0.66rem;
      color: #fdfdfd;
    }
  }
  &-top-content_score {
    width: 1.8rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &-bottom {
    background: #fff;
    padding-top: 0.62rem;
    margin-top: -1rem;
    border-top-left-radius: 0.32rem;
    border-top-right-radius: 0.32rem;
  }
  &-bottom-explain {
    padding: 0.16rem 0;
    width: 6.9rem;
    height: 2.3rem;
    box-sizing: border-box;
    margin: auto;
    background: rgba(#d8d8d8, 0.3);
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 0.3rem;
    color: #333333;
    .title {
      width: 2rem;
      text-align: right;
      margin-right: 0.2rem;
      color: #999;
    }
  }
  &-bottom-des {
    font-size: 0.3rem;
    color: #333333;
    font-weight: 600;
    text-align: center;
    margin-top: 0.8rem;
    .key {
      font-size: 0.3rem;
      line-height: 0.2rem;
      border-bottom: 0.1rem solid rgba(#27c190, 0.5);
      display: inline-block;
    }
  }
  &-img {
    width: 1.8rem;
    height: 1.8rem;
    margin: auto;
    display: block;
    margin-top: 0.3rem;
  }
  &-back {
    width: 6.9rem;
    height: 0.88rem;
    margin: auto;
    display: block;
    margin-top: 0.5rem;
    font-size: 0.32rem;
  }
}
</style>
